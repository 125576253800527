<template>
   <div class="loading">
      <img src="../assets/img/loading_sushi.gif">
   </div>
</template>
<script>
export default {
      
}
</script>
<style scoped>
.loading{
  background: #ffffff;
  height: calc(100vh);
  width: 100%;
  text-align: center;
  position: relative;
}
.loading img{
  width: 360px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>