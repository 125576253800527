<template>
      <div class="istruction-content">
            <div v-html="istruction"></div>
            <div style="margin:30px  0;">
                  <van-button @click="closeAlert" style="font-size:1.5em; border-radius:5px;" size="large" type="primary">Menu</van-button>
            </div>
      </div>
</template>
<script>
export default {
      name: 'Istruction',
      props:['istruction'],
      methods:{
            closeAlert(){
                  this.$parent.$parent.closeAlert();
            }
      }
}
</script>
<style>
.istruction-content{
    padding: 50px 15px 30px;
    line-height: 18px;
}
</style>