<template>
  <div class="home" v-cloak>
    <loading v-if="isLoading" />
    <div v-if="!isLoading">
      <dishes-list v-if="dishes" :data="dishes" />
      <cart-bar />
      <dishes-detail :dishes="detail" :showDetail="showDetail" />
      <dishes-menu-detail v-if="showMenuDetails" :menuId="menuId" :showMenuDetails="showMenuDetails" />
      <cart-list :showCart="showcart" />
      <van-popup v-if="istruction" closeable round v-model="$store.state.showHomeAlert" :style="{width:'478px', maxWidth:'100%', minHeight:'300px', height:'90%'}">
        <home-page-alert v-if="istruction" :istruction="istruction" />
      </van-popup> 
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DishesList from '@/components/DishesList.vue'
import CartBar from '@/components/CartBar.vue'
import DishesDetail from '@/components/DishesDetail.vue'
import DishesMenuDetail from '@/components/DishesMenuDetail.vue'
import CartList from '@/components/Cart.vue'
import Loading from '@/components/Loading.vue'
import HomePageAlert from '@/components/HomePageAlert.vue'
export default {
  name: 'Home',
  components: {
    DishesList,
    CartBar,
    DishesDetail,
    DishesMenuDetail,
    CartList,
    Loading,
    HomePageAlert
  },
  data(){
   return{
      isLoading: true,
      showDetail: false,
      showcart: false,
      homePageAlert: true,
      istruction:'',
      dishes:[],
      detail: {},
      showMenuDetails: false,
      menuId: 0
    }
  },

  created(){
    this.fetchData()
  },
  methods:{
     fetchData(){
      this.axios.get('/webapi/categories/getDishes').then(res=>{
        const data = JSON.parse(res.data.data)

        this.istruction = data.istruction
        this.dishes = data.dishes
      }).then(()=>{
        setTimeout(()=>{this.isLoading = false},2000)
      })
    },
    showHomeAlert(){
      this.$store.state.showHomeAlert = true
    },
    closeAlert(){
      this.$store.state.showHomeAlert = false
    },
    showCartList(){
      this.showcart = true
    },
    closeCart(){
         this.showcart = false          
    },
    closeDishesDetail(){
      this.showDetail = false
    },
    showDishesDetail(dishes){
      this.detail = dishes
      this.showDetail = true
    },
    showMenuDetail(id){
      this.menuId = id
      this.showMenuDetails = true
    },
    closeDishesMenuDetail(){
      this.showMenuDetails = false
    }
  }

}
</script>
