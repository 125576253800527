import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('@/views/Checkout.vue'),
  },
  {
    path: '/stripepay',
    name: 'PayStripe',
    component: () => import('@/views/Stripepay.vue'),
  },
  {
    path: '/success/:order',
    name: 'Success',
    component: () => import('@/views/Success.vue')
  },
  {
    path: '/pay/:sessionId',
    name: 'Pay',
    component: () => import('@/views/Pay.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
