<template>
      <div class="cart-bar">
            <div class="bar-design">
                  <div class="cart-icon">
                        <div @click="showCart" class="cart-icon-position">
                              <van-icon color="#ffffff" size="60" name="cart-circle-o" />
                              <div class="cart-count">{{parseFloat(cartCount) + parseFloat($store.state.menuFixed.length)}}</div>
                        </div>
                       
                  </div>
                   <div class="cart-bar-total-price">€{{(parseFloat(totalPrice) + parseFloat($store.getters.menuTotalPrice)).toFixed(2)}}</div>
                  <div @click="showCart" class="checkout-button">Carrello</div>
            </div>
      </div>
</template>
<script>
export default {
      computed:{
            cartCount() {
                  return this.$store.getters.cartCount
            },
            totalPrice() {
                  return this.$store.getters.totalPrice
            }
      },
      methods:{
            showCart(){
                  this.$parent.showCartList()
            }
      }
}
</script>

<style>
.cart-bar{
      width: 100%;
      height: 60px;
      background:#3d3e40;
      position: absolute;
      z-index: 999;
      bottom: 0;
}
.bar-design, .cart-icon-position{
      position: relative;
}
.cart-icon{
      position: absolute;
      left: 5%;
      top: -20px;
      
}
.bar-design .van-icon{
      width: 70px;
      height: 70px;
      background:red;
      border-radius: 50%;
      text-align: center;
      line-height: 70px;
}
.cart-count{
      position: absolute;
      left: 40px;
      top: 5px;
      height: 15px;
      width: 15px;
      color: #ffffff;
      background: #3d3e40;
      padding: 5px;
      border-radius: 50%;
      text-align: center;
      line-height: 15px;
}
.checkout-button{
      width: 220px;
      height: 60px;
      background: linear-gradient(#f28e26,#fd644f);
      position: absolute;
      right: 0;
      z-index: 999999999999;
      color: #ffffff;
      font-size: 1.8em;
      text-align: center;
      line-height: 60px;
}
.cart-bar-total-price{
      color: #ffffff;
      position: absolute;
      left: calc(5% + 80px);
      top: 20px;
      font-size: 1.3em;
}
.cart-total-price{
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 1.3em;
}
.wl-label{
      font-weight: bold;
      padding: 0 10px 10px;
      display: block;
      font-size: 1.1em;
      float: left;
}
.cart-total-price .total-price{
      float: right;
      padding: 0 10px 10px;
      font-weight: bold;
      font-size: 1.1em;
}
@media only screen and (max-width: 628px) {
       .checkout-button{
            width: 160px;
       }
     
}
</style>