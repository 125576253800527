<template>
    <van-popup :style="{width: '428px'}" :close-on-click-overlay="false" @click-overlay="closeCart" class="cart-list-container" v-model="showCart" position="right"> 
       <div>
          <div>
               <van-nav-bar title="Carrello" left-text="Indietro" right-text="Svuota" left-arrow @click-left="closeCart" @click-right="emptyCart" />
          </div>
          <div v-if="this.$store.state.cart.length == 0 && this.$store.state.menuFixed.length == 0" class="cart-is-empty">
                <van-empty
                  class="custom-image"
                  image="https://img.yzcdn.cn/vant/custom-empty-image.png"
                  description="Il carrello è vuoto"
                  />
          </div>
          <div v-if="this.$store.state.cart.length > 0 || this.$store.state.menuFixed.length > 0">

                <div class="carts-dishes-items">
                      <div class="carts-dishes-item" v-for="menuItem in this.$store.state.menuFixed">
                             <div class="carts-delete-button"><van-icon @click="deleteMenuFixed(menuItem.dishes.id)" color="#0000003b" size="22" name="clear" /></div>
                              <div class="carts-thumb-img">
                                  <img style="width:100%; height:100%;" :src="menuItem.dishes.img">
                              </div>
                            <div class="carts-info">
                                  <h4>{{menuItem.dishes.name}}</h4>
                                  <ul class="menu-fixed-items">
                                           <li v-for="itemDishes in menuItem.dishesSelected">
                                                 {{itemDishes.id}}. {{itemDishes.name}}
                                           </li>  
                                  </ul>
                                  <div class="carts-price-button-box">
                                        <div class="carts-info-button">         
                                                <div class="carts-buttom-plus-minus">
                                                      <h4 class="carts-menu-price">1  x €{{menuItem.dishes.price}}</h4>
                                                </div>
                                        </div>
                                  </div>
                            </div>
                          <div class="clearfix"></div>  
                     </div>

                      <div class="carts-dishes-item" v-for="(item, index) in this.$store.state.cart" :key="index">
                             <div class="carts-delete-button"><van-icon @click="deleteItem(item.id)" color="#0000003b" size="22" name="clear" /></div>
                              <div class="carts-thumb-img">
                                  <img style="width:100%; height:100%;" :src="item.img">
                              </div>
                            <div class="carts-info">
                                  <h4>{{item.name}}</h4>
                                  <div class="carts-price-button-box">
                                        <div class="carts-info-button">
                                              <h4 class="carts-price">€{{ (item.quantity * item.price).toFixed(2)}}</h4>
                                                <div class="carts-buttom-plus-minus">
                                                      <van-stepper v-model="$store.state.cart[index].quantity" theme="round"
                                                      @minus="minusDishes(item)"
                                                      @plus="addToCart(item)"
                                                      button-size="25" disable-input /> 
                                                </div>
                                        </div>
                                  </div>
                            </div>
                          <div class="clearfix"></div>  
                     </div>
                </div>
                  <div class="cart-total-price clearfix">
                       <div class="wl-label">Totale ordine:</div>
                       <div class="total-price"> €{{(parseFloat($store.getters.totalPrice) + parseFloat($store.getters.menuTotalPrice)).toFixed(2)}}</div>
                  </div>
                <van-button @click="$router.push('/checkout')" color="linear-gradient(to right, #f28e26,#fd644f)" size="large">Concludi ordine</van-button>
          </div>

          </div>
    </van-popup>  
</template>
<script>
import Vue from "vue";
import { Dialog, Notify } from "vant";
Vue.use(Dialog, Notify);
export default {
      name: 'Cart',
      props:{
            showCart: {
                  type: Boolean,
                  default: false
            }
      },
      data(){
            return{

            }
      },
      created(){
 
      },
      methods:{
            closeCart(){
                  this.$parent.closeCart()
            },
            addToCart(item) {
      this.$store.commit("addToCart", item);
      Notify({
        type: "success",
        message: item.name + " è stato aggiunto al carrello",
        duration: 1000,
      });
    },
    //删除整条菜品
    deleteItem(id) {
      this.$store.commit("deleteItem", id);
    },
    deleteMenuFixed(id){
      this.$store.commit("deleteMenuFixed", id);
    },
    //菜品数量减少
    minusDishes(dishes) {
      this.$store.commit("minusDishes", dishes);
    },
    emptyCart() {
            Dialog.confirm({
            confirmButtonText: "Comferma",
            cancelButtonText: "Annulla",
            title: "Svuota",
            message: "Comfermi svuotare il carrello?",
            }).then(() => {
            this.$store.commit("emptyCart");
            });
      },
}
}
</script>
<style>
.cart-list-container{
      max-width: 100%;
      width: 428px;
      height: 100%;
}
.cart-close-button{
      margin-bottom: 10px;
}
.cart-is-empty{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
}
.van-empty__description{
      width: 100%;
      text-align: center;
}
.carts-dishes-items{
      display: block;
      width: 100%;
}
.carts-dishes-item{
      margin: 15px 0;
      border-bottom:1px solid #e5e5e5e5;
      padding-bottom: 15px; 
      position: relative; 
}
.clearfix{
      clear: both;
}
.carts-delete-button{
      float: left;
      width: 50px;
      text-align: center;
      line-height: 80px;
}
.carts-thumb-img{
      width: 80px;
      height: 80px;
      float: left;
      margin-left:10px;
}
.carts-thumb-img img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
}
.carts-info{
    float: left;
    width: calc(100% - 160px);
    padding: 5px;
}

.carts-price-button-box{
      position: absolute;
      bottom: 10px;
      
}
.carts-info-button{
      width: calc(100% + 140px);
}
.carts-price{
      color: red;
      width: 100%;
      position: relative;
}

.carts-buttom-plus-minus{
    
    text-align: right;
}

.menu-fixed-items{
      font-size: 12px;
      color: #353434;
}
</style>