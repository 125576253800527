<template>
 <div> 
   <van-nav-bar
    :title="siteName"
    left-text="Home"
    right-text="Istruzione"
    left-arrow
    @click-left="goHomePage"
    @click-right="$parent.showHomeAlert()" />
   <div >
    <cube-scroll-nav class="wl-container"
      v-if="data.length"
      :side="true"
      :data="data"
      :current="current"
    >

    <cube-scroll-nav-panel
      v-for="categorie in data"
      :key="categorie.title"
      :label="categorie.title"
      :title="categorie.title">
      <div class="dishes-container">
            <div class="dishes-item" v-for="(dishe, index) in categorie.dishes" :key="index">
              <div class="dishes-box" v-if="dishe.is_menu == 1">
                          <div @click="showMenuDetailsClick(dishe.id)" class="thumb">
                                <van-image width="100%" height="100%" :src="dishe.img" />
                                <ul class="dishes-allergens">
                                      <li v-for="(allergen, index) in dishe.allergens" :key="index"><img :src="allergen.img"></li>
                                </ul>
                          </div>
                          <div class="dishes-info">
                            <h3 class="dishes-title"><em>{{dishe.code}}</em> {{dishe.name}}</h3>
                            <div class="dishes-description"> {{dishe.description}}</div>
                            <div class="wl-button">
                            <span class="price">€ {{dishe.price}}</span>
                          </div>
                    </div>
                  </div>
                  <div class="dishes-box" v-if="dishe.is_menu == 2">
                          <div @click="showDishesDetail(dishe)" class="thumb">
                                <van-image width="100%" height="100%" :src="dishe.img" />
                                <ul class="dishes-allergens">
                                      <li v-for="(allergen, index) in dishe.allergens" :key="index"><img :src="allergen.img"></li>
                                </ul>
                          </div>
                          <div class="dishes-info">
                          <h3 class="dishes-title"><em>{{dishe.code}}</em> {{dishe.name}}</h3>
                          <div class="dishes-description"> {{dishe.description}}</div>
                          <div class="wl-button">
                          <span class="price">€ {{dishe.price}}</span>
                          <span class="add-to-cart" @click="addToCart(dishe)">
                          <van-icon size="30" color="#ff0000" name="cart-circle-o" />
                          </span>
                          </div>
                    </div>
                  </div>
              </div>
          </div>
        </cube-scroll-nav-panel>
        <div style="height:150px; width:100%;"></div>
      </cube-scroll-nav>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload, Notify } from "vant";
Vue.use(Lazyload);
export default {
  name: 'DishesList',
  props: {
    data: Array
  },
  data(){
    return {
      siteName: process.env.VUE_APP_SITE_NAME,
      current: null,
    }
  },

  created(){
    
  },
  methods:{
    goHomePage(){
      window.location.href = process.env.VUE_APP_BASE_URL
    },
    addToCart(item) {
      this.$store.commit('addToCart', item)
      Notify({
        type: "success",
        message: item.name + " è stato aggiunto al carrello",
        duration: 1000,
      });
    },

    showDishesDetail(dishe){
          this.$parent.showDishesDetail(dishe)
    },

  
    closeMenuDetail(){
      this.showMenuDetails = false
    },

    showMenuDetailsClick(id){
       this.$parent.showMenuDetail(id)
    },

   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.wl-container{
  height: calc(100vh - 46px);
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #ffffff;
}
.wl-content{
  height: 100%;
  width: 100%;
  position: relative;
}
.cube-scroll-nav-bar-items{
  width: 120px;
}
.cube-scroll-content{
      margin-bottom: 200px;
}
.cube-scroll-nav-bar-item{
      background: #f7f7f7;
}
.cube-scroll-nav-bar-item_active{
      background: #ffffff;
}
.cube-scroll-nav-bar-items{
      padding-bottom: 100px;
      background: #f7f7f7;
}
.cube-scroll-nav-panel-title{
  height: 50px;
  background: #ffffff;
  line-height: 50px;
  padding-left: 10px;
  font-weight: bold;
  clear: both;
}

.dishes-item{
      display: inline-flex;
      flex: 1;
      flex-wrap: nowrap;
      width: 22.33333%;
      margin: 15px;

}
.dishes-box{
      flex: 1;
      width: 90%;
      padding: 5px;
      border:1px solid rgba(12 12 10 / 0.16);
      border-radius: 10px;
      box-shadow: 7px 8px 5px 1px rgba(12 12 10 / 0.16);
}
.dishes-box .thumb{
       width: 100%;
       height: 220px;
       position: relative;
}
.dishes-box .thumb .van-image img{
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
}
.dishes-info{
  width: 100%;
  padding: 5px;
}
.wl-button{
  position: relative;
}

.wl-button .price{
  color: red;
  font-size: 1.3em;
}
.wl-button .add-to-cart{
  position: absolute;
  right: 10px;
  top: -10px;
}
h3.dishes-title{
  width: 97%;
  font-size: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dishes-title em{
  color: #ffffff;
  background: black;
  padding:1px 3px;
  font-size: 14px;
  border-radius: 5px;
}
.dishes-description{
  width: 97%;
  position: relative;
  clear: both;
  color: #878787f0;
  height: 20px;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dishes-allergens{
  position: absolute;
  z-index: 999;
  bottom: -15px;
}
.dishes-allergens{
  position: absolute;
  z-index: 999;
  bottom: 5px;
  padding: 0 5px;
}
.dishes-allergens li{
  display: block;
  float: left;
  padding: 2px;
}
.dishes-allergens img{
  width: 30px;
  height: 30px;
  float: left;
}
@media only screen and (max-width: 1400px) {
      .dishes-item{
            width: 30%;
      }
}

@media only screen and (max-width: 978px) {
      .dishes-item{
            width: 45%;
      }
      .cube-scroll-nav-bar-items{
            padding-bottom: 200px;
      }
}

@media only screen and (max-width: 628px) {
      .dishes-item{
            width: 90%;
      }
      .dishes-box .thumb{
            height: 160px;
      }
      .cube-scroll-nav-bar-items{
            padding-bottom: 200px;
      }
     
}
</style>
