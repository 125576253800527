<template>
      <van-popup class="dishes-detail-container" :lazy-render="true" round @closed="closeDishesDetail" v-model="showDetail">
            <div v-if="dishes">
                  <div class="detail-image">
                        <div @click="closeDishesDetail" class="wl-close-icon"><van-icon size="30" name="clear" /></div>
                        <img style="width:100%;" :src="dishes.img" alt="">
                  </div>
                  <div class="detail-info">
                        <h3 class="dishes-title">{{dishes.name}}</h3>
                        <h3 class="dishes-title detail-price">€{{dishes.price}}</h3>
                        <div v-if="dishes.description" class="dishes-detail-description">
                              <strong>Ingredienti / Descrizione</strong>
                              <p>{{dishes.description}}</p>
                        </div>
                        <ul v-if="dishes.allergens" class="dishes-detail-allergens">
                              <strong v-if="dishes.allergens.length">Allergeni</strong>
                              <li v-for="(allergen, index) in dishes.allergens" :key="index">
                                    <div class="dishes-detail-allergens-item">
                                          <img :src="allergen.img" alt="">
                                          {{allergen.name}}
                                    </div>
                              </li>
                        </ul>
                  </div>
            </div>
      </van-popup>
</template>
<script>
export default {
      name:'DishesDetail',
      props:{
            showDetail: Boolean,
            dishes: Object
      },
      methods:{
            closeDishesDetail(){
                  this.$parent.closeDishesDetail()
            }
      }
           
}
</script>
<style>
.dishes-detail-container{
      width: 428px;
      height: 628px;
      max-width: 100%;
}
.detail-image{
      width: 100%;
      height: 300px;
      position: relative;
}
.wl-close-icon{
      position: absolute;
      top: 20px;
      left: 20px;
}
.detail-image img{
      width: 100%;
      height: 100%;
      object-fit: cover;
}
.detail-info{
      padding: 5px;
}
.detail-info h3.dishes-title{
      font-size: 1.5em;
      margin-bottom: 10px;
}
.detail-price{
      text-align: right;
      color: red;
}
.dishes-detail-description{
      margin-top: 15px;
   
}

.dishes-detail-description strong{
      display: block;
      margin-bottom: 10px;
  
}
.dishes-detail-allergens{
      margin: 30px 0;
      clear: both;
}
.dishes-detail-allergens-item{
      margin-top: 10px;
}
.dishes-detail-allergens li{
      height: 30px;
      line-height: 30px;
      float: left;
      margin: 5px;
}
.dishes-detail-allergens-item img{
      width: 30px;
      height: 30px;
}
.dishes-detail-allergens strong{
      display: block;
      clear: both;
}
@media only screen and (max-width: 628px) {
    .detail-image{
            width: 100%;
            height: 280px;
      }  
     
}
</style>