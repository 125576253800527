<template>
      <van-popup :style="{maxHeight:'668px', overflow:'hidden'}" class="dishes-detail-container" :lazy-render="true" round @closed="closeDishesMenuDetail" v-model="showMenuDetails">
      <div class="menu-fixed-detail">
      <div v-if="data">
            <div class="menu-banner">
            <img :src="data.item.img" />
            <div class="menu-banner-bg"></div>
            <div class="menu-banner-content">
                  <h3>{{data.item.name}}</h3>
                  <p v-html="data.item.description"></p>
            </div>  
      </div> 
      <div v-if="data">
          <van-checkbox-group v-model="dishesSelected" :max="data.seletion.limit">
            
            <div class="selection-padding">
                  <van-checkbox @click="is_complete" v-for="(item, index) in dishes" :key="index" :name="item.id">
                        <div>
                              <div style="height:60px; line-height:60px; float:left; border-radius:5px;">
                                    <img v-if="item.img" style="width:60px; height:60px;" :src="item.img">
                              <img v-if="!item.img" style="width:60px; height:60px; border-radius:5px; float:left;" src="https://ristoe.oss-eu-central-1.aliyuncs.com/fWeA6SWZfr/1d67dc68cde9638869899f8047cee11a.jpg">
                              </div>
                              <div style="margin-left:5px; height:60px; line-height:60px; float:left;">
                                    {{item.code}}. {{item.name}}
                              </div>
                        </div>
                        <div style="clear: both;"></div>
                  </van-checkbox>        
            </div>
          </van-checkbox-group> 
      </div>

    </div>


      <div v-if="data" class="menu-fixed-bar">
            <div class="menu-add-bar">
                  <div class="limit-display">Piatti: {{this.dishesSelected.length}} / {{this.data.seletion.limit}}</div>
                  <div class="button-add-to-cart-menu">
                        <van-button @click="addToCartMenu" :disabled="this.data.seletion.limit != this.dishesSelected.length" size="large" square type="primary">Aggiungi al carrello</van-button>
                  </div>
            </div>
      </div>
      </div>
      </van-popup>
    
</template>
<script>
import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);
export default {
props: ['showMenuDetails','menuId'],
  data() {
    return {
          data: null,
          dishes: [],
          dishesSelected:[],
    }
  },
  created() {
      this.fetchData()
  },
  methods: {
        closeDishesMenuDetail(){
                  this.$parent.closeDishesMenuDetail()
            },
        addToCartMenu(){
              let menuItem = []
              this.dishesSelected.forEach(id =>{
                    this.data.dishes.forEach(item =>{
                          if(id == item.id){
                                menuItem.push({id:item.code, name:item.name})
                                return
                          }
                    })
              })
            let data = {dishesSelected :menuItem, dishes: this.data.item}
            this.$store.commit('addMenuFixed', data)
            this.$parent.closeDishesMenuDetail()
         },

        is_complete(){
              if(this.dishesSelected.length == this.data.seletion.limit){
                    Toast('Hai raggiunto ' + this.data.seletion.limit + ' piatti del menu fisso')
              }
        },
        fetchData(){
            this.axios.get('/webapi/menufisso/detail?id=' + this.menuId)
            .then((res)=>{
                 this.data = res.data
                 this.dishes = [];
                  res.data.seletion.menu_dishes.forEach(id => {
                       res.data.dishes.forEach(item =>{
                             if(id == item.id){
                                   this.dishes.push(item)
                                   return
                             }
                       })
                 });

            })
        },
        onClickLeft(){
              this.showMenuDetails = false
              this.$parent.$parent.closeMenuDetail()
        }
  },
}
</script>
<style>
.menu-fixed-detail{
            width: 100%;
            max-height: 618px;
            overflow-x: hidden;
            overflow-y: scroll;
            position: relative;
      }
      .menu-banner{
            width: 100%;
            min-height: 260px;
            position: relative;
      }
      .menu-banner img{
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
      }
      .menu-banner-bg{
           position: absolute;
           width: 100%;
           height: 100%; 
           background: #0000005e;
      }
      .menu-banner-content{
           position: absolute;
           color: #ffffff;
           top: 50%;
           left: 50%;
           transform: translate(-50%, -50%);
           width: 100%;
           text-align: center;
           padding: 15px;
      }
      .menu-banner-content h3{
            margin-bottom: 15px;
            font-size: 26px;
            font-weight: bold;
      }
      .menu-banner-content p{
            padding: 5px 35px;
      }
      .selection-padding {
            margin-bottom: 60px;
      }
      .selection-padding .van-checkbox{
            padding: 10px;
      }
      .menu-fixed-bar{
            width: 100%;
            height: 60px;
            position: fixed;
            bottom: 0px;
            background: #ffffff;
            border-top:1px solid #f3f3f3;
            z-index: 9999;
            
      }

      .limit-display{
            float: left;
            height: 60px;
            line-height: 50px;
            padding-left: 10px;
            z-index: 999999;
      }
      .button-add-to-cart-menu{
            width: 70%;
            height: 60px;
            float: right;
            text-align: center;
            z-index: 999;
      }
@media only screen and (max-width: 628px) {
    .detail-image{
            width: 100%;
            height: 280px;
      }  
     
}
</style>