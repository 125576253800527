import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showHomeAlert: true,
    cart: JSON.parse(window.localStorage.getItem("cart") || "[]"),
    menuFixed: JSON.parse(window.localStorage.getItem("menuFixed") || "[]")
  },
  mutations: {

    addMenuFixed(state, item){
      state.menuFixed.push(item)
      localStorage.setItem('menuFixed', JSON.stringify(state.menuFixed))
    },
    deleteMenuFixed(state, id) {
      const index = state.menuFixed.findIndex((item) => item.id == id);
      state.menuFixed.splice(index, 1);
      localStorage.setItem('menuFixed', JSON.stringify(state.menuFixed))
    },
    addToCart(state, item) {
      const cartItem = state.cart.findIndex((dishes) => dishes.id == item.id);
      if (cartItem == -1) {
        item["quantity"] = 1;
        state.cart.push(item);
      } else {
        state.cart[cartItem].quantity++;
        Vue.set(state.cart, cartItem, state.cart[cartItem]);
      }
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    deleteItem(state, id) {
      const index = state.cart.findIndex((item) => item.id == id);
      state.cart.splice(index, 1);
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    //菜品数量减少
    minusDishes(state, dishes) {
      const index = state.cart.findIndex((item) => item.id == dishes.id);
      state.cart[index].quantity--;
      Vue.set(state.cart, index, state.cart[index]);
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    //清空回收站
    emptyCart(state){
      state.cart = []
      state.menuFixed = []
      localStorage.setItem('cart', JSON.stringify(state.cart))
      localStorage.setItem('menuFixed', JSON.stringify(state.menuFixed))
    },
  },
  getters:{
    cartCount(state) {
      return state.cart.reduce((sum, dishes) => {
        return sum + dishes.quantity;
      }, 0);
    },
    totalPrice(state) {
      let amount =  state.cart
        .reduce((sum, dishes) => {
          return sum + dishes.quantity * dishes.price;
        }, 0).toFixed(2);
        return amount
    },
    menuTotalPrice(state){
      return state.menuFixed
        .reduce((sum, menu) => {
          return sum + parseFloat(menu.dishes.price);
        }, 0).toFixed(2);
    }
  },
  actions: {
  },
  modules: {
  }
})
